import styled from 'styled-components';
import { pxtorem } from '../../utils/tools';
import { StyledCartSummary } from '../CartSummary/CartSummary.style';
import { StyledLink } from '../Generic/Cta/Cta.style';
import { StyledMiniCart } from '../MiniCart/MiniCart.style';

export const StyledCartPage = styled.div`
  --x-padding: min(5%, ${pxtorem(42)});
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  max-height: 100vh;
  background-color: #eae9e5;
  overflow: hidden;
  height: 100%;

  .page-hero {
    position: relative;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-end;
    width: 100%;

    .hero-content {
      text-align: center;
      position: absolute;
      z-index: 4;
      left: 50%;
      transform: translateX(-50%);
      top: 10%;
      margin-inline: auto;
      width: 100%;

      h1 {
        max-width: 9ch;
        font-size: 60px;
        margin-inline: auto;
        margin-bottom: ${pxtorem(24)};
      }

      ${StyledLink} {
        font-weight: 500;
      }
    }

    .gatsby-image-wrapper {
      width: 100%;
      z-index: 2;
    }
  }

  .page-right {
    position: relative;

    width: 100%;
    max-width: 37.5vw;

    ${StyledMiniCart} {
      background-color: var(--white);
      padding: ${pxtorem(20)} 0;
    }

    ${StyledCartSummary} {
      position: absolute;
      bottom: 0;
    }
  }

  .scroll-area {
    max-height: calc(100% - var(--summary-height));
  }

  @media (max-width: 768px) {
    flex-direction: column;
    max-height: 100%;
    --x-padding: min(3.5%, ${pxtorem(20)});

    .scroll-area {
      padding-bottom: var(--summary-height);
    }

    ${StyledLink} {
      width: auto;
    }

    .page-right ${StyledCartSummary} {
      position: fixed;
    }

    .page-hero {
      max-height: 60vh;
    }
  }
`;
