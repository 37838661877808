import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy';
import { graphql } from 'gatsby';
import Layout from '~/components/Layout/Layout';
import Metadata from '~/components/Metadata';
import { CartPage } from '../components/CartPage/CartPage';
import { GatsbyImageData } from '../types/types';

const Cart: React.FC<CartProps> = ({ data }) => {
  const translate = useTranslate();
  return (
    <Layout showHeader={false} showFooter={false} showMiniCart={false}>
      <Metadata title={translate('cart.page_title')} />
      <CartPage data={data} />
    </Layout>
  );
};

export const query = graphql`
  query CartPage {
    page: contentfulPage(slug: { eq: "cart" }) {
      name
      hero {
        title
        cta {
          raw
        }
        media {
          ... on ContentfulContentCard {
            media {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;

export interface CartPageData {
  page: {
    hero: {
      title: string;
      cta: {
        raw: string;
      };
      media: GatsbyImageData[]
    };
  };
}

interface CartProps {
  data: CartPageData;
}

export default Cart;
