import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { CartPageData } from '../../pages/cart';
import { Cta } from '../Generic/Cta/Cta';
import { MiniCart } from '../MiniCart/MiniCart';
import { StyledCartPage } from './CartPage.style';

export const CartPage: React.FC<CartPageProps> = ({ data }) => {
  const { page } = data;
  const { cta, title, media } = page.hero;
  const parsedCta = JSON.parse(cta.raw);
  const [, ctaData] = parsedCta.content[0].content;
  return (
    <StyledCartPage>
      <div className="page-hero">
        <div className="hero-content">
          <h1>{title}</h1>
          <Cta href={ctaData.data.uri}>{ctaData.content[0].value}</Cta>
        </div>
        <GatsbyImage image={media?.[0].media.gatsbyImageData} alt={''} />
      </div>
      <div className="page-right">
        <MiniCart />
      </div>
    </StyledCartPage>
  );
};

interface CartPageProps {
  data: CartPageData;
}
